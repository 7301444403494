import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getInstance } from '@/auth'
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    // Auth Routes
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/auth/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/create-account',
    //   name: 'create-account',
    //   component: () => import('@/views/auth/CreateAccount.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/forgot-password',
    //   name: 'forgot-password',
    //   component: () => import('@/views/auth/ForgotPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import('@/views/AccountSetting.vue'),
      meta: {
        // navActiveLink: 'groups-listing',
        // pageTitle: 'Account Settings',
        // breadcrumb: [
        //   {
        //     text: 'Account Settings',
        //     active: false,
        //   },
        // ],
        requireAuth: true,
      },
    },
    {
      path: '/phone-verification',
      name: 'phone-verification',
      component: () => import('@/views/auth/PhoneVerification.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/delete-user-info',
      name: 'delete-user-info',
      component: () => import('@/views/landingPage/DeleteUserInfoPage.vue'),
      meta: {
        layout: 'full',
        requireAuth: false,
      },
    },
    {
      path: '/otp-code',
      name: 'otp-code',
      component: () => import('@/views/auth/OtpCode.vue'),
      meta: {
        layout: 'full',
        requireAuth: true,
      },
    },
    {
      path: '/complete-profile',
      name: 'complete-profile',
      component: () => import('@/views/auth/CompleteProfile.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'full',
        requireAuth: true,
      },
    },
    // groups Routes
    {
      path: '/pricing-plans',
      name: 'pricing-plans',
      component: () => import('@/views/groups/PricingPlans.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/edit-packages/:id',
      name: 'edit-packages',
      component: () => import('@/views/groups/EditPackages.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/upgrade-package/:id',
      name: 'upgrade-package',
      component: () => import('@/views/groups/PackageCheckout.vue'),
      beforeEnter: authGuard,
      meta: {
        requiredAuth: true,
      },
    },
    {
      path: '/admin/applications',
      name: 'admin-applications',
      component: () => import('@/views/admin/Applications.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/groups/Checkout.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/groups-listing',
      name: 'groups-listing',
      component: () => import('@/views/groups/GroupsListing.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/applications',
      name: 'applications',
      props: route => ({ id: route.query.id }),
      component: () => import('@/views/groups/Applications.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/group-members',
      name: 'group-members',
      props: route => ({ id: route.query.id }),
      component: () => import('@/views/groups/GroupMembers.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },

    {
      path: '/billing-details',
      name: 'billing-details',
      props: route => ({ id: route.query.id }),
      component: () => import('@/views/billingDetailsAdmin/index.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/billing-details/change-card',
      name: 'change-card',
      props: route => ({ id: route.query.id }),
      component: () => import('@/views/groups/ChangeCard.vue'),
      beforeEnter: authGuard,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/invitation',
      name: 'invitation',
      props: route => ({ key: route.query.key }),
      component: () => import('@/views/groups/InvitationRequest.vue'),
      meta: {
        layout: 'full',
      },
    },
    // End Auth Routes
    // {
    //   path: '/dashboard',
    //   beforeEnter: authGuard,
    //   name: 'dashboard',
    //   component: () => import('@/views/Dashboard.vue'),
    //   meta: {
    //     pageTitle: 'Dashboard',
    //     breadcrumb: [
    //       {
    //         text: 'Dashboard',
    //         active: true,
    //       },
    //     ],
    //     requireAuth: true,
    //   },
    // },
    {
      path: '/callback',
      name: 'callback',
      component: () => import('@/views/Callback.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'full',
        pageTitle: 'Callback',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Logout',
      },
    },
    {
      path: '/',
      name: 'home',
      beforeEnter() {
        window.location.href = 'https://simiconnect.com/'
      },
      component: () => import('@/views/landingPage/Home.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Home',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const setSelectedGroupId = to => {
  store.commit('user/SET_SELECTED_GROUP_ID', to.query ? Number(to.query.groupId) : null)
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
const getUserDataIfRequired = async () => {
  const appInitialized = store.getters['user/getAppInitialized']
  if (!appInitialized) {
    const instance = getInstance()
    if (instance.isAuthenticated) {
      await store.dispatch('user/getUserIfNotExist')
        .then(async () => {
          await store.dispatch('user/setAllUserGroupIfNotPresent')
        })
        .catch(async () => {
          //
        }).finally(async () => {
          await store.commit('user/SET_APP_INITIALIZED')
        })
      // await fn(instance)
    } else {
      await store.commit('user/SET_APP_INITIALIZED')
    }
  }
}

const waitForAuth0ToBeInitialized = async () => {
  const appInitialized = store.getters['app/getAuth0Initialized']
  if (!appInitialized) {
    const instance = getInstance()
    await new Promise(resolve => {
      instance.$watch('loading', async loading => {
        if (loading === false) {
          await store.commit('app/SET_AUTH0_INITIALIZED')
          resolve('')
        }
      })
    })
  }
}

const checkForAuth0Authentication = async to => {
  if (to.query && to.query.logout) {
    Vue.prototype.$tabEvent.emit('logout-user')
    await Vue.prototype.$auth.logout({ localOnly: true })
    window.close()
  }
}

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, from, next) => {
  await waitForAuth0ToBeInitialized()
  await checkForAuth0Authentication(to)
  setSelectedGroupId(to)
  await getUserDataIfRequired()
  const { isAuthenticated } = Vue.prototype.$auth
  if (to.meta.requireAuth) {
    if (isAuthenticated) {
      const user = store.getters['user/getUser']
      const isUserAdmin = store.getters['user/isUserAdmin']
      const isOtpSend = store.getters['user/isOtpSend']
      if (!user.id) {
        Vue.prototype.$auth.logout({ returnTo: window.location.origin })
        this.$store.dispatch('user/setUser', {})
        next({
          path: '/',
        })
      }
      if (isUserAdmin && to.path !== '/admin/applications') {
        next({
          path: '/admin/applications',
        })
      }
      if (!isUserAdmin && !user.is_phone_number_verified && !isOtpSend && to.name !== 'phone-verification') {
        next({
          name: 'phone-verification',
        })
      }
      if (!isUserAdmin && !user.is_profile_completed && to.name !== 'complete-profile' && user.is_phone_number_verified) {
        next({
          name: 'complete-profile',
        })
      }
    }
  }
  next() // make sure to always call next()!
})
export default router
